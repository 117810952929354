import React, { useState, useEffect } from 'react';
import axios from "axios"; 
import FileUpload from './FileUpload';
import ProgressDynamicUpdate from './ProgressDynamicUpdate';
import IngredientsTabContainer from '../IngredientsTabContainer';

const UploadImageWizard = () => {
    const [currentStep, setCurrentStep] = useState(0); // Initial step is 0 (first step)
    const [progressValue, setProgressValue] = useState(100);
    const [viewResults, setviewResults] = useState(false);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState("");
    const [productName, setProductName] = useState('');
    const [productBrand, setProductBrand] = useState('');
    const [messageType, setMessageType] = useState('');
    const [shouldShowProgress, setShouldShowProgress] = useState(false);
    const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false); // New state to track file upload status

    const uploadLambdaUrl = "https://pz4c2tij2g2ulliew6qd36udqi0iwzfr.lambda-url.us-east-1.on.aws";
    const getStatusLambdaUrl = "https://2sj2esjvxigx4ikky52o2mi5du0hhrbp.lambda-url.us-east-1.on.aws/";
    const steps = [
        { id: 0, title: "Provide Brand Details" },
        { id: 1, title: "Upload Image" },
        { id: 2, title: "Processing Status" },
        { id: 3, title: "Results" },
    ];

    const goToNextStep = () => {
        if (currentStep < steps.length - 1) {
            if (currentStep === 1) {
                setShouldShowProgress(true); // Enable ProgressDynamicUpdate on step transition
            }
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            if (currentStep === 2) {
                setShouldShowProgress(false); // Disable ProgressDynamicUpdate on back transition
            }
            setCurrentStep(currentStep - 1);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const supportedTypes = ["image/jpeg", "image/png", "image/jpg"];
            if (!supportedTypes.includes(selectedFile.type)) {
                setMessageType("error");
                setMessage("Unsupported file type. Please upload a JPEG or PNG image.");
                return;
            }
        }
        setFile(selectedFile);
        setMessage(""); // Reset error message
    };

    // Handle file upload to Lambda
    const handleUpload = async () => {
        if (!file || !productName || !productBrand) {
            setMessageType('error');
            setMessage("Please fill in all required fields and select a file.");
            return;
        }

        setUploading(true);
        setMessageType('');
        setMessage("");

        // Convert the file to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
            const base64File = reader.result.split(',')[1]; // Remove data URL prefix

            // Prepare payload for Lambda
            const payload = {
                productName,
                productBrand,
                fileContent: base64File,
                fileName: file.name,
                fileType: file.type
            };

            try {
                const result = await axios.post(uploadLambdaUrl, payload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                setUploading(false);

                // Check if the response contains the file URL
                if (result.data && result.data.fileUrl) {
                    const fileUrl = result.data.fileUrl;

                    // Store the URL in sessionStorage and update the state
                    sessionStorage.setItem('uploadedFileUrl', fileUrl);
                    setUploadedFileUrl(fileUrl); // Store URL in state to pass to IngredientsTabContainer

                    setMessageType('success');
                    setMessage(`File uploaded successfully! URL: ${fileUrl}`);
                    setIsFileUploaded(true); // Set file as uploaded
                } else {
                    setMessageType('error');
                    setMessage("File uploaded successfully, but no URL returned.");
                }

            } catch (error) {
                setUploading(false);
                setMessageType('error');
                console.error("Upload error details:", error);
                setMessage(`Failed to upload file. Error: ${error.message}`);
            }
        };
    };

    // New function that will be called when the "View Results" button is clicked
    const handleViewResults = () => {
        if (isFileUploaded) {
            setCurrentStep(3); // Move to results step
            setviewResults(true);
        } else {
            setMessageType('error');
            setMessage('Please upload a file before viewing results.');
        }
    };

    // Reset form and set currentStep to 0
    const handleClose = () => {
        setCurrentStep(0);
        setProgressValue(100);
        setFile(null);
        setUploading(false);
        setMessage("");
        setProductName('');
        setProductBrand('');
        setMessageType('');
        setShouldShowProgress(false);
        setUploadedFileUrl(null); // Reset uploaded file URL
        setIsFileUploaded(false); // Reset file upload status
        sessionStorage.removeItem('uploadedFileUrl');
    };

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-xl">Upload Image</h6>
                    <p className="text-neutral-500">
                        Provide the Product details and proceed to the next steps.
                    </p>

                    {/* Form Wizard Start */}
                    <div className="form-wizard">
                        <form>
                            <div className="form-wizard-header overflow-x-auto scroll-sm pb-8 my-32">
                                <ul className="list-unstyled form-wizard-list">
                                    {steps.map((step, index) => (
                                        <li
                                            key={step.id}
                                            className={`form-wizard-list__item ${
                                                index === currentStep ? 'active' : ''
                                            }`}
                                        >
                                            <div className="form-wizard-list__line">
                                                <span className="count">{index + 1}</span>
                                            </div>
                                            <span className="text text-xs fw-semibold">
                                                {step.title}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Fieldsets */}
                            {steps.map((step, index) => (
                                <fieldset
                                    key={step.id}
                                    className={`wizard-fieldset ${
                                        index === currentStep ? 'show' : ''
                                    }`}
                                >
                                    {index === 0 && (
                                        <>
                                            <div className="row gy-3">
                                                <div className="card-header">
                                                    <h5 className="card-title mb-0">Product Details</h5>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="form-label">Brand Name*</label>
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            id="product-brand"
                                                            value={productBrand}
                                                            onChange={(e) => setProductBrand(e.target.value)}
                                                            className="form-control wizard-required"
                                                            placeholder="Enter Brand Name"
                                                            required
                                                        />
                                                        <div className="wizard-form-error" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <label className="form-label">Product Name*</label>
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            id="product-name"
                                                            value={productName}
                                                            onChange={(e) => setProductName(e.target.value)}
                                                            className="form-control wizard-required"
                                                            placeholder="Enter Product Name"
                                                            required
                                                        />
                                                        <div className="wizard-form-error" />
                                                    </div>
                                                </div>
                                                <div className="form-group text-end">
                                                    <button
                                                        type="button"
                                                        onClick={goToNextStep}
                                                        className="form-wizard-next-btn btn btn-primary-600 px-32"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {index === 1 && (
                                        <>
                                            <div className="row gy-3">
                                                <FileUpload
                                                    handleFileChange={handleFileChange}
                                                    handleUpload={handleUpload}
                                                    uploading={uploading}
                                                    message={message}
                                                    messageType={messageType}
                                                />
                                                <div className="form-group d-flex align-items-center justify-content-end gap-8">
                                                    <button
                                                        type="button"
                                                        onClick={goToPreviousStep}
                                                        className="form-wizard-previous-btn btn btn-neutral-500 border-neutral-100 px-32"
                                                    >
                                                        Back
                                                    </button>
                                                    {messageType === "success" && (
                                                        <button
                                                            type="button"
                                                            onClick={goToNextStep}
                                                            className="form-wizard-next-btn btn btn-primary-600 px-32"
                                                        >
                                                            Next
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {index === 2 && shouldShowProgress && (
                                        <>
                                            <div className="row gy-3">
                                                <ProgressDynamicUpdate/>
                                                <div className="form-group d-flex align-items-center justify-content-end gap-8">
                                                    <button
                                                        type="button"
                                                        onClick={goToPreviousStep}
                                                        className="form-wizard-previous-btn btn btn-neutral-500 border-neutral-100 px-32"
                                                    >
                                                        Back
                                                    </button>
                                                    {progressValue === 100 && (
                                                        <button
                                                            type="button"
                                                            onClick={handleViewResults} // Trigger handleViewResults on click
                                                            className="form-wizard-next-btn btn btn-primary-600 px-32"
                                                        >
                                                            View Results
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {index === 3 && (
                                        <>
                                            <div className="text-center mb-40">
                                                {uploadedFileUrl && viewResults && (
                                                    <IngredientsTabContainer s3_url={uploadedFileUrl} />
                                                )}
                                            </div>
                                            <div className="form-group d-flex align-items-center justify-content-end gap-8">
                                                <button
                                                    type="button"
                                                    onClick={goToPreviousStep}
                                                    className="form-wizard-previous-btn btn btn-neutral-500 border-neutral-100 px-32"
                                                >
                                                    Back
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={handleClose}
                                                    className="form-wizard-submit btn btn-primary-600 px-32"
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </fieldset>
                            ))}
                        </form>
                    </div>
                    {/* Form Wizard End */}
                </div>
            </div>
        </div>
    );
};

export default UploadImageWizard;
