import React from 'react';

const IngredientsTab = ({ records }) => {
  // Check if records are passed correctly
  console.log(records);

  // Group records by ingredient name
  const groupedIngredients = records.reduce((acc, record) => {
    const { name, type, description, risk } = record;
    if (!acc[name]) {
      acc[name] = { benefits: [], harms: [], risk };
    }
    acc[name][type === 'benefit' ? 'benefits' : 'harms'].push({ description });
    return acc;
  }, {});

  // Check the grouped ingredients structure
  console.log(groupedIngredients);

  const ingredientNames = Object.keys(groupedIngredients);

  // Function to get the color class based on the risk level
  const getRiskClass = (risk) => {
    switch (risk) {
      case 'High':
        return 'text-danger';  // Red for High risk
      case 'Moderate':
        return 'text-warning';  // Yellow for Moderate risk
      case 'Low':
        return 'text-success';  // Green for Low risk
      default:
        return 'text-muted';  // Gray for Unknown risk
    }
  };

  return (
    <div className="col-xxl-12">
      <div className="card p-0 overflow-hidden position-relative radius-12 h-100">
        <div className="card-header py-16 px-24 bg-base border border-end-0 border-start-0 border-top-0">
          <h6 className="text-lg mb-0">Ingredients</h6>
        </div>
        <div className="card-body p-24 pt-10">
          <div className="d-flex align-items-start">
            <ul
              className="nav button-tab nav-pills flex-column me-3 overflow-auto"
              id="ingredients-tab"
              role="tablist"
              style={{ maxHeight: '1200px' }}
            >
              {ingredientNames.map((name, index) => (
                <li className="nav-item" role="presentation" key={index}>
                  <button
                    className={`nav-link fw-semibold text-primary-light radius-4 px-16 py-10 ${index === 0 ? 'active' : ''}`}
                    id={`ingredient-tab-${index}`}
                    data-bs-toggle="pill"
                    data-bs-target={`#ingredient-content-${index}`}
                    type="button"
                    role="tab"
                    aria-controls={`ingredient-content-${index}`}
                    aria-selected={index === 0}
                  >
                    {name}
                  </button>
                </li>
              ))}
            </ul>
            <div className="tab-content flex-grow-1" id="ingredients-tabContent">
              {ingredientNames.map((name, index) => (
                <div
                  className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                  id={`ingredient-content-${index}`}
                  role="tabpanel"
                  aria-labelledby={`ingredient-tab-${index}`}
                  tabIndex={0}
                  key={index}
                >
                  <div className="flex-grow-1">
                    <h6 className={`text-lg mb-8 ${getRiskClass(groupedIngredients[name]?.risk)}`}>
                      {name} (Risk: {groupedIngredients[name]?.risk})
                    </h6>

                    <div className="mb-16">
                      <h6 className="text-md mb-4">Benefits</h6>
                      {groupedIngredients[name]?.benefits?.length > 0 ? (
                        groupedIngredients[name].benefits.map((benefit, idx) => (
                          <p className="text-secondary-light mb-8" key={idx}>
                            {benefit.description}
                          </p>
                        ))
                      ) : (
                        <p className="text-secondary-light">No benefits listed.</p>
                      )}
                    </div>

                    <div>
                      <h6 className="text-md mb-4">Harms</h6>
                      {groupedIngredients[name]?.harms?.length > 0 ? (
                        groupedIngredients[name].harms.map((harm, idx) => (
                          <p className="text-secondary-light mb-8" key={idx}>
                            {harm.description}
                          </p>
                        ))
                      ) : (
                        <p className="text-secondary-light">No harms listed.</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IngredientsTab;
