import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useState } from 'react'

const FileUpload = ({ handleFileChange, handleUpload, uploading, message, messageType }) => {
    return (
        <div className="col-md-12">
            <div className="card h-100 p-0">
                <div className="card-header border-bottom bg-base py-16 px-24">
                    <h6 className="text-lg fw-semibold mb-0">File Upload</h6>
                </div>
                <div className="card-body p-24">
                    
                    <input
                        type="file"
                        className="form-control w-auto mt-24 form-control-lg"
                        id="basic-upload"
                        onChange={handleFileChange} // handle file change
                    />
                    <div className="form-group d-flex align-items-center justify-content-end gap-8">
                    <button
                        onClick={handleUpload} // handle file upload
                        disabled={uploading} // disable button when uploading
                        className="form-wizard-next-btn btn btn-primary-600 px-32"
                    >
                        {uploading ? "Uploading..." : "Upload"} {/* Change text based on upload status */}
                    </button>
                    </div>
                </div>
                {message && (
                    <div
                        className="alert alert-success bg-success-100 text-success-600 border-success-100 px-24 py-11 mb-0 fw-semibold text-lg radius-8 d-flex align-items-center justify-content-between"
                        role="alert"
                    >
                        <div className="d-flex align-items-center gap-2">
                            <Icon
                                icon="akar-icons:double-check"
                                className="icon text-xl"
                            />
                            {message}
                        </div>
                            <button className="remove-button text-success-600 text-xxl line-height-1">
                                {" "}
                                <Icon icon="iconamoon:sign-times-light" className="icon" />
                            </button>
                        </div>
                )}
            </div>
        </div>
    )
}

export default FileUpload;
