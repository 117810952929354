import React from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import UploadImageLayer from "../components/UploadImageLayer"

const UploadImagePage = () => {
  return (
    <>
      {/* MasterLayout */}
      <MasterLayout>
        {/* Breadcrumb */}
        <Breadcrumb title="Upload Image" />


        {/* DashBoardLayerOne */}
        <UploadImageLayer />

      </MasterLayout>
    </>
  );
};

export default UploadImagePage;