import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import UploadImagePage from "./pages/UploadImagePage";
import ViewProductPage from "./pages/ViewProductPage";



function App() {
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
        <Route exact path="/" element={<UploadImagePage />} />
        <Route exact path="/view-products" element={<ViewProductPage />} />



        {/* SL */}



        <Route exact path="*" element={<ErrorPage />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;



