import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import IngredientsTabContainer from '../IngredientsTabContainer';

const ProductDisplayCard = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageModalShow, setImageModalShow] = useState(false); // State for image modal

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://7m24ne4hpmbvkrwgrzttuojs3e0htwxr.lambda-url.us-east-1.on.aws');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setProducts(data.data || []);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    const handleModalOpen = (imageUrl) => {
        setSelectedImage(imageUrl);
        setModalShow(true);
    };

    const handleModalClose = () => {
        setModalShow(false);
        setSelectedImage(null);
    };

    const handleImageModalOpen = (imageUrl) => {
        setSelectedImage(imageUrl);
        setImageModalShow(true);
    };

    const handleImageModalClose = () => {
        setImageModalShow(false);
        setSelectedImage(null);
    };

    const modalStyle = {
        dialog: {
            maxWidth: '90%',
            margin: 'auto',
        },
        content: {
            height: '70vh',
            overflowY: 'auto',
        },
    };

    return (
        <div className="mb-40">
            <h6 className="mb-24">Product List</h6>
            <div className="row gy-4">
                {products.map((product, index) => (
                    <div className="col-xl-6" key={index}>
                        <div className={`card radius-12 overflow-hidden h-100 d-flex align-items-center flex-nowrap flex-row ${index % 2 !== 0 ? 'flex-row-reverse' : ''}`}>
                            <div
                                className="d-flex flex-shrink-0 w-170-px h-100"
                                onClick={() => handleImageModalOpen(product.image_url)} // Open image modal on click
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    src={product.image_url}
                                    className="h-100 w-100 object-fit-cover"
                                    alt={`${product.ProductNmame} image`}
                                />
                            </div>
                            <div className="card-body p-16 flex-grow-1">
                                <h5 className="card-title text-lg text-primary-light mb-6">
                                    {product.ProductCompany} - {product.ProductNmame}
                                </h5>
                                <p className="card-text text-neutral-600 mb-16" style={{ fontSize: '0.9rem' }}>
                                    Ingredients({product.total_ingredients}): {product.ingredients_list}
                                </p>
                                <Button
                                    variant="primary"
                                    onClick={() => handleModalOpen(product.image_url)}
                                    className="d-inline-flex align-items-center gap-2"
                                >
                                    Get Ingredients Summary
                                    <Icon
                                        icon="iconamoon:arrow-right-2"
                                        className="text-xl"
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Ingredients Modal */}
            <Modal
                show={modalShow}
                onHide={handleModalClose}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ingredients Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalStyle.content}>
                    <div className="text-center mb-20">
                        {selectedImage && <IngredientsTabContainer s3_url={selectedImage} />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Image Modal */}
            <Modal
                show={imageModalShow}
                onHide={handleImageModalClose}
                centered
                size="lg"
            >
                <Modal.Body className="text-center">
                    {selectedImage && <img src={selectedImage} alt="Product" className="img-fluid" />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleImageModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProductDisplayCard;
