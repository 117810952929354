import React, { useState, useEffect } from 'react';
import axios from 'axios';
import IngredientsTab from './child/IngredientsTab'; // Assuming IngredientsTab is in the same directory

const IngredientsTabContainer = ({ s3_url }) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const fetchIngredientsData = async () => {
    if (!s3_url) return; // Do not make a request if no s3_url is provided
    
    setLoading(true);
    setError(null);
    
    try {
      // Send the s3_url as part of the request body to the Lambda function
      const response = await axios.post('https://4bmmazqeyvg2hj25gmcxt3mvpe0htoef.lambda-url.us-east-1.on.aws/', {
        s3_url: s3_url, // Passing the s3_url to the Lambda function
      });
      console.log(response.data);
      
      // Assuming the response contains a "flattened_record" field
      setRecords(response.data.flattened_records || []); // Use flattened_record if it exists
    } catch (err) {
      setError(err.message || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (s3_url) {
      fetchIngredientsData(); // Trigger fetch when s3_url is available
    }
  }, [s3_url]); // Dependency array ensures fetch is called when s3_url changes

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p className="text-danger">{error}</p>}
      <IngredientsTab records={records} /> {/* Pass the records array to IngredientsTab */}
    </div>
  );
};

export default IngredientsTabContainer;
