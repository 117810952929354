import React from 'react'
import UploadImageWizard from './child/UploadImageWizard'

const UploadImageLayer = () => {

    return (
        <>
            <section className="row gy-4 mt-1">

                <UploadImageWizard />
                {/* ImageUploadLayer */}


            </section>
        </>


    )
}

export default UploadImageLayer