import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ProgressDynamicUpdate = ({ value, children }) => {
    const [progressValue, setProgressValue] = useState(value || 0); // Fallback to 0 if value is not provided
    const [processingStatus, setProcessingStatus] = useState('Analysis in progress');
    const [error, setError] = useState(null);
    const [refreshing, setRefreshing] = useState(false); // State to handle refresh button feedback

    useEffect(() => {
        // Timer to update progress
        const timer = setInterval(() => {
            setProgressValue(prev => {
                const newProgress = prev + 4;
                if (newProgress >= 100) {
                    clearInterval(timer); // Stop the timer once it reaches 100%
                    return 100;
                }
                return newProgress;
            });
        }, 1000); // Update every second

        // Update processing status based on progress
        const updateProcessingStatus = (progress) => {
            if (progress <= 25) {
                setProcessingStatus('Image Analysis in Progress...');
            } else if (progress > 25 && progress <= 50) {
                setProcessingStatus('Ingredients being identified...');
            } else if (progress > 50 && progress <= 90) {
                setProcessingStatus('Health summary is being compiled...');
            } else if (progress > 90 && progress < 100) {
                setProcessingStatus('Processing results...');
            } else if (progress === 100) {
                setProcessingStatus('Ready to see the results.');
            }
        };

        // Update status based on progress value
        const statusTimer = setInterval(() => {
            updateProcessingStatus(progressValue);
        }, 1000);

        // After 30 seconds, set processing status to "Processing completed"
        const completionTimer = setTimeout(() => {
            if (progressValue < 100) {
                setProcessingStatus('Processing completed');
            }
        }, 30000); // 30 seconds

        return () => {
            clearInterval(timer); // Cleanup timer on component unmount
            clearInterval(statusTimer); // Cleanup status update timer
            clearTimeout(completionTimer); // Cleanup completion timer
        };
    }, [progressValue]);

    return (
        <div className="col-sm-12">
            <div className="card p-0 overflow-hidden position-relative radius-12">
                <div className="card-header py-16 px-24 bg-base border border-end-0 border-start-0 border-top-0">
                    <h6 className="text-lg mb-0">Overall Progress</h6>
                </div>
                <div className="card-body p-24">
                    <div className="d-flex align-items-center flex-column gap-4">
                        <div
                            className="progress h-8-px w-100 bg-primary-50"
                            role="progressbar"
                            aria-label="Progress example"
                            aria-valuenow={progressValue}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated rounded-pill bg-primary-600"
                                style={{ width: `${progressValue}%` }}
                            />
                        </div>
                        <div className="mt-2">
                            {processingStatus ? (
                                <p>{processingStatus}</p>
                            ) : (
                                error && <p className="text-danger">{error}</p>
                            )}
                        </div>
                        {children && <div>{children}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

ProgressDynamicUpdate.propTypes = {
    value: PropTypes.number,
    children: PropTypes.node,
};

ProgressDynamicUpdate.defaultProps = {
    value: 0,
    children: null,
};

export default ProgressDynamicUpdate;
