import React from 'react'
import ProductDisplayCard from './child/ProductDisplayCard'


const ViewProductLayer = () => {
    return (
        <>

            {/* HorizontalCard */}
            <ProductDisplayCard />

        </>
    )
}

export default ViewProductLayer